import React, { ReactElement, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { usePageTheme, useApp } from '@Context/AppContext';
import SEO from '@Components/SEO';
import ContentfulModule from '@Src/components/ContentfulModule';
import { IBlock, IPageData, IPageProps } from '@Types/ContentfulTypes';
import ContentLayout from '@Src/components/sitestructure/ContentLayout';
import '@GraphQL/fragments';
import LogoBanner from '@Src/components/LogoBanner';

export interface Props extends IPageProps {
	data: {
		contentfulPageTypeHero: IPageData;
	};
}

const HeroPage = ({ data, location, transitionStatus, pageContext }: Props): ReactElement => {
	const { title, seoSettings, theme, content } = data.contentfulPageTypeHero;
	const { setTheme: setPageTheme } = usePageTheme();
	const { mode, pageType, setPageType, setPageTitle } = useApp();
	const [localTransStatus, setLocalTransStatus] = useState<string>(transitionStatus);
	useEffect(() => {
		if (pageType === 'index' && transitionStatus == 'exiting') {
			setLocalTransStatus(transitionStatus + 'Index');
		} else {
			setLocalTransStatus(transitionStatus);
		}

		if (transitionStatus == 'entering' || transitionStatus == 'entered') {
			setPageTheme(mode == 'day' ? 'yellow' : 'dark');
			setPageTitle(title);
			setPageType(pageContext.type);
		}
	}, [transitionStatus]);

	useEffect(() => {
		if (transitionStatus == 'entered') {
			setPageTheme(mode == 'day' ? 'yellow' : 'dark');
		}
	}, [mode]);

	return (
		<ContentLayout transitionState={localTransStatus} type={'hero'}>
			<SEO
				location={location.pathname}
				title={seoSettings?.title || title}
				description={seoSettings?.description}
				meta={seoSettings?.metaTags}
				image={seoSettings?.image}
			/>
			{content &&
				content.map(
					(module: IBlock, index: number): ReactElement => (
						<ContentfulModule key={module.id} type={module.type} data={module} />
					)
				)}
		</ContentLayout>
	);
};

export default HeroPage;

export const query = graphql`
	query ($id: String!) {
		contentfulPageTypeHero(id: { eq: $id }) {
			... on IPageNode {
				...PageFields
				content {
					... on IBlockNode {
						...AllBlocks
					}
				}
			}
		}
	}
`;
